import invariant from 'invariant';
import { noop } from 'lodash';
import React, { SFC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import FadeTransition from '../FadeTransition';
import Ink from '../Ink';
import PulseProgress from '../PulseProgress';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<
  | 'disabled'
  | 'error'
  | 'flat'
  | 'loader'
  | 'loading'
  | 'outline'
  | 'plain'
  | 'primary'
  | 'raised'
  | 'root'
  | 'secondary'
  | 'success'
  | 'warning'
>(theme => ({
  root: {
    ...theme.font('control'),
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'antialiased',
    '-webkit-tap-highlight-color': 'transparent',
    borderRadius: 3,
    cursor: 'pointer',
    display: 'inline-block',
    letterSpacing: 0.3,
    lineHeight: 1.8,
    outline: 0,
    padding: '0 0.67em',
    position: 'relative',
    textAlign: 'center',
    transition: [
      'background-color 240ms',
      'box-shadow 240ms',
      'color 240ms',
      'border-color 240ms',
      'filter 120ms',
    ].join(','),
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
  disabled: {
    background: theme.color('grey', '50'),
    color: theme.color('text.disabled'),
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loading: {
    background: theme.color('grey', '50'),
    color: 'rgba(0,0,0,0)',
  },
  primary: {
    color: theme.color('primary', '700'),
  },
  secondary: {
    color: theme.color('secondary', '700'),
  },
  error: {
    color: theme.color('error', '700'),
  },
  success: {
    color: theme.color('success', '700'),
  },
  warning: {
    color: theme.color('warning', '700'),
  },
  plain: {
    '&:hover': {
      backgroundColor: theme.color('grey', '50'),
    },
  },
  flat: {
    backgroundColor: theme.color('grey', '50'),
    '&$primary': {
      backgroundColor: theme.color('primary'),
      color: theme.color('primary', 'contrast'),
    },
    '&$secondary': {
      backgroundColor: theme.color('secondary'),
      color: theme.color('secondary', 'contrast'),
    },
    '&$error': {
      backgroundColor: theme.color('error'),
      color: theme.color('error', 'contrast'),
    },
    '&$success': {
      backgroundColor: theme.color('success'),
      color: theme.color('success', 'contrast'),
    },
    '&$warning': {
      backgroundColor: theme.color('warning'),
      color: theme.color('warning', 'contrast'),
    },
    '&:hover': {
      filter: 'brightness(97%)',
    },
  },
  outline: {
    border: `1px solid ${theme.color('grey', '600')}`,
    '&$primary': {
      borderColor: theme.color('primary', '700'),
    },
    '&$secondary': {
      borderColor: theme.color('secondary', '700'),
    },
    '&$error': {
      borderColor: theme.color('error', '700'),
    },
    '&$success': {
      borderColor: theme.color('success', '700'),
    },
    '&$warning': {
      borderColor: theme.color('warning', '700'),
    },
  },
  raised: {
    backgroundColor: theme.color('grey', '50'),
    boxShadow: [
      `1px 1px 2px ${theme.color('grey', '300')}`,
      `1px 1px 8px ${theme.color('grey', '200')}`,
      `3px 3px 14px ${theme.color('grey', '50')}`,
    ].join(','),
    '&$primary': {
      backgroundColor: theme.color('primary', '300'),
      color: theme.color('primary', 'contrast'),
    },
    '&$secondary': {
      backgroundColor: theme.color('secondary', '300'),
      color: theme.color('secondary', 'contrast'),
    },
    '&$error': {
      backgroundColor: theme.color('error', '300'),
      color: theme.color('error', 'contrast'),
    },
    '&$success': {
      backgroundColor: theme.color('success', '300'),
      color: theme.color('success', 'contrast'),
    },
    '&$warning': {
      backgroundColor: theme.color('warning', '300'),
      color: theme.color('warning', 'contrast'),
    },
    '&:hover': {
      filter: 'brightness(92%)',
    },
  },
}));

export interface ButtonProps
  extends Pick<partial<linkprops>, Exkludera<keyof LinkProps,="" 'innerRef'="">> {
  disabled?: boolean;
  loading?: boolean;
  format?: 'flat' | 'raised' | 'outline';
  type?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
}

type Props = WithStyles<buttonprops, typeof="" styles="">;

const getTag = ({ disabled, href, to }: ButtonProps) => {
  if (!disabled && to) return Link;
  if (!disabled && href) return 'a';
  return 'span';
};

const Button: SFC<props> = ({
  children,
  classes,
  className,
  disabled,
  format = 'plain',
  href,
  loading,
  onClick,
  theme,
  to,
  type,
  ...rest
}) => {
  invariant(!href || !to, 'Only `href` or `to` should be specified.');
  // FIXME: Since TS 3.2, it cannot determine the type of `Tag` as callable.
  const Tag: any = getTag({ disabled, href, to });

  return (
    <tag {...{="" href,="" to,="" ...rest="" }}="" tabIndex="{disabled" ?="" -1="" :="" 0}="" role="{'button'}" className="{cx(" classes.root,="" className,="" disabled="" &&="" classes.disabled,="" loading="" classes.loading,="" type="" classes[type],="" format="" (classes="" as="" any)[format],="" )}="" onClick="{disabled" ||="" noop="" onClick}="">
      {barn}
      <ink disabled="{disabled}"></ink>
      <fadetransition mountOnEnter="" unmountOnExit="" appear="" in="{loading" &&="" !disabled}="">
        {rekvisita => <pulseprogress {...props}="" className="{classes.loader}"></pulseprogress>}
      </fadetransition>
    </tag>
  );
};

export default withStyles(styles)(Button);
</props></buttonprops,></keyof></partial<linkprops>