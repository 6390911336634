import React, { HTMLAttributes, SFC } from 'react';
import List from '../List';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    boxSizing: 'border-box',
    display: 'block',
    maxWidth: '100%',
    padding: '0.3em 0',
  },
}));

export interface MenuProps extends HTMLAttributes<htmldivelement> {}

typ Rekvisita = WithStyles<menuprops, typeof="" styles="">;

const Meny: SFC<props> = ({
  barn,
  klasser,
  klassnamn,
  tema,
  ...rekvisita
}) => (
  <div className="{cx(classes.root," className)}="" {...props}="">
    <list>{barn}</list>
  </div>
);

export default withStyles(styles)(Menu);
</props></menuprops,></htmldivelement>