typ Kallelsebar<t, U="" extends="" any[]=""> = (...args: U) => T;

exportera standard <t, U="" extends="" any[]="">(f: T | Kallbar<t, U="">) => (
  ...args: U
): T => {
  if (typeof f === 'function') {
    return (f as Callable<t, U="">)(...args);
  }
  return f;
};
</t,></t,></t,></t,>