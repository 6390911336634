/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DEFAULT_MODULAR_SCALE,
  DEFAULT_SHADOWS,
  DEFAULT_SPACING,
} from './constants';
import { kebabCase, uniq } from 'lodash';
import { Breakpoints } from './breakpoints';
import { Palette } from './palette';
import { ThemeInput } from './theme-input';
import { ThemeJson } from './theme-json';
import { ThemeVariables } from './theme-variables';
import { Typography } from './typography';
import cx from '@robotsnacks/classname';
import { immerable } from 'immer';
import { modularScale } from '../modular-scale';

const DEFAULT_PREFIX = 'mint';

/**
 * Map of component custom CSS properties used internally. When component
 * register their own properties their added to this map.
 */
const componentCustomProperties: { [name: string]: string | number } = {};

const makeVariables = <tcustombreakpoint extends="" string="never">(
  tema: ThemeJson<tcustombreakpoint>,
  prefix: sträng,
): TemaVariabler<tcustombreakpoint> => {
  const resolve = (val: any, path: string[]): any => {
    if (typeof val === 'string' || typeof val === 'number') {
      return path.join('-');
    } else {
      return Object.entries(val).reduce(
        (curr, [key, v]): any => ({
          ...curr,
          [key]: resolve(v, [
            ...path,
            /^h\d$/.test(key) ? key : kebabCase(key),
          ]),
        }),
        {},
      );
    }
  };
  return resolve(theme, ['-', prefix]);
};

export class Theme<tcustombreakpoint extends="" string="never"> {
  /**
   * Statisk fabriksmetod.
   * @param input Theme input-objekt.
   */
  allmän statisk create<tcustombreakpoint extends="" string="never">(
    inmatning: TemaInput<tcustombreakpoint> = {},
  ): Theme<tcustombreakpoint> {
    returnera nytt tema<tcustombreakpoint>(input);
  }

  public static registerComponentCssProperty(
    name: string,
    value: number | string,
  ): void {
    componentCustomProperties[name] = value;
  }

  /**
   * Theme breakpoints and their values.
   */
  public breakpoints: Breakpoints<tcustombreakpoint>;

  public modularScale: number;

  /**
   * Shadows, keyed 0-24 typically.
   */
  public shadows: { [z: number]: string };

  /**
   * Color palette used for action components.
   */
  public palette: Palette;

  public name: string = DEFAULT_PREFIX;

  /**
   * Theme typography styles.
   */
  public typography: Typography<tcustombreakpoint>;

  /**
   *
   */
  offentliga vars: TemaVariabler<tcustombreakpoint>;

  /**
   * Flagga som gör den här klassen oföränderlig med immer.
   */
  skyddad readonly [immerable] = true;

  /**
   * Privat referens till JSON för stödtema som är oföränderligt. Nytt tema
   *-instanser kan skapas med hjälp av immer.
   */
  privat tema: ThemeJson<tcustombreakpoint>;

  private calculateModularScaleStep: (step: number) => number;

  /**
   * Skapar en ny Them-instans.
   * @param input Theme-objekt för inmatning.
   */
  public constructor(input: ThemeInput<tcustombreakpoint>) {
    const brytpunkter = Brytpunkter.toJSON<tcustombreakpoint>(
      inmatning.brytpunkter,
    );

    const palette = Palette.toJSON(input.palette);

    const typografi = Typografi.toJSON<tcustombreakpoint>(
      breakpoints,
      input.typography,
    );

    this.theme = {
      breakpoints,
      modularScale: input.modularScale || DEFAULT_MODULAR_SCALE,
      shadows: { ...DEFAULT_SHADOWS, ...input.shadows },
      palette,
      spacing: input.spacing || DEFAULT_SPACING,
      typography,
    };

    this.breakpoints = Breakpoints.create<tcustombreakpoint>(input.breakpoints);

    this.modularScale = this.theme.modularScale;
    this.calculateModularScaleStep = modularScale(this.modularScale);

    this.shadows = this.theme.shadows;

    this.typography = Typography.create(
      this.breakpoints.toJSON(),
      input.typography,
    );

    this.palette = Palette.create(input.palette);

    this.vars = makeVariables(this.theme, 'mint');
  }

  public cx(
    ...input: (string | string[] | boolean | null | undefined)[]
  ): string {
    const classes = cx(...input)
      .split(' ')
      .map((c): string => {
        if (c.startsWith(DEFAULT_PREFIX) || c.startsWith('fl-')) {
          return c;
        } else {
          return [DEFAULT_PREFIX, c].join('-');
        }
      });

    if (!classes.includes(DEFAULT_PREFIX)) {
      classes.push(DEFAULT_PREFIX);
    }

    return uniq(classes).join(' ');
  }

  public ms(m1: number): number;
  public ms(m1: number, u: string): string;
  public ms(m1: number, m2: number): number[];
  public ms(m1: number, m2: number, u: string): string;
  public ms(m1: number, m2: number, m3: number): number[];
  public ms(m1: number, m2: number, m3: number, u: string): string;
  public ms(m1: number, m2: number, m3: number, m4: number): number[];
  public ms(m1: number, m2: number, m3: number, m4: number, u: string): string;
  public ms(...args: any[]): number | string | number[] {
    let units: string | undefined;
    let values: number[] = [];

    args.forEach((v): void => {
      if (typeof v === 'number') {
        values.push(this.calculateModularScaleStep(v));
      } else if (typeof v === 'string') {
        units = v;
      }
    });

    if (typeof units === 'string' && units.length > 0) {
      return values.map((v): string => `${v.toFixed(4)}${units}`).join(' ');
    } else if (values.length > 0) {
      return values;
    } else {
      return values[0];
    }
  }

  public toCssVariables(): string {
    const values: string[] = [];
    const resolve = (vars: any, vals: any): any => {
      if (typeof vars === 'string' || typeof vars === 'number') {
        values.push(`${vars}: ${vals};`);
      } else {
        return Object.entries(vars).reduce(
          (curr, [key, v]): any => ({
            ...curr,
            [key]: resolve(v, vals[key]),
          }),
          {},
        );
      }
    };
    resolve(this.vars, this.theme);
    for (const [k, v] of Object.entries(componentCustomProperties)) {
      values.push(`${this.prop(k)}: ${v};`);
    }
    return values.sort().join('\n');
  }

  public makeCustomProperties(props: string[]): string[] {
    return props.map((prop): string => this.prop(prop));
  }

  public prop(prop: string): string {
    return ['-', DEFAULT_PREFIX, kebabCase(prop)].join('-');
  }

  public spacing(m1: number): number;
  public spacing(m1: number, u: string): string;
  public spacing(m1: number, m2: number): number[];
  public spacing(m1: number, m2: number, u: string): string;
  public spacing(m1: number, m2: number, m3: number): number[];
  public spacing(m1: number, m2: number, m3: number, u: string): string;
  public spacing(m1: number, m2: number, m3: number, m4: number): number[];
  public spacing(
    m1: number,
    m2: number,
    m3: number,
    m4: number,
    u: string,
  ): string;
  public spacing(...args: any[]): number | string | number[] {
    let units: string | undefined;
    let values: number[] = [];

    args.forEach((v): void => {
      if (typeof v === 'number') {
        values.push(v * this.theme.spacing);
      } else if (typeof v === 'string') {
        units = v;
      }
    });

    if (typeof units === 'string' && units.length > 0) {
      return values.map((v): string => `${v}${units}`).join(' ');
    } else if (values.length > 0) {
      return values;
    } else {
      return values[0];
    }
  }
}
</tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint>