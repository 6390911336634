import React, { SFC } from 'react';
import ListSection, { ListSectionProps } from '../ListSection';

export type GridListSectionProps = ListSectionProps;

const GridListSection: SFC<gridlistsectionprops> = rekvisita => (
  <listsection {...props}=""></listsection>
);

export default GridListSection;
</gridlistsectionprops>