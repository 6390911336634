import React, { HTMLAttributes, SFC, createElement } from 'react';
import SortableItem from '../SortableItem';
import { cx } from '../utils';

/**
 * Default tag to render if one isn't specified.
 */
const DEFAULT_TAG = 'div';

/**
 * Properties for the <sortable></sortable> komponent.
 * @property order Ordningen för detta sorterbara objekt (anges av överordnad behållare).
 * @property sortClassName Klass som ska tillämpas vid sortering.
 * @property sortKey Sorteringsnyckeln för det här objektet.
 * @property tagg Den tagg som ska användas vid rendering av denna komponent.
 */
export interface SortableProps extends HTMLAttributes<any> {
  disabled?: boolean;
  order?: number;
  sortClassName?: string;
  sortKey: string;
  tag: keyof JSX.IntrinsicElements;
}

/**
 * Convenience component for quickly and easily creating a sortable item.
 * @param props Component properties.
 */
const Sortable: SFC<sortableprops> = ({
  klassnamn,
  inaktiverad,
  sorteraClassName,
  sorteraKey,
  tag = DEFAULT_TAG,
  order,
  ...rest
}) => (
  <sortableitem sortKey="{sortKey}" order="{order}" disabled="{disabled}">
    {({ domRef, onMouseDown, onTouchStart, sorting, style }) =>
      createElement(tag, {
        ...rest,
        'data-sort-key': sortKey,
        className: cx(sorting && sortClassName, className),
        onMouseDown,
        onTouchStart,
        ref: domRef,
        style: {
          ...rest.style,
          ...style,
        },
      })
    }
  </sortableitem>
);

export default Sorterbar;
</sortableprops></any>