import {
  Theme,
  TypographyVariant,
  WithTheme,
  assertTheme,
  cx,
  interpolateFont,
  useTheme,
} from '@mint/core';
import { UseInkOptions, useInk } from '../ink';
import styled, { css } from 'styled-components';
import { ButtonBaseProps } from './button-base-props';
import { ButtonGroup } from '../button-group';
import { ButtonIcon } from '../button-icon';
import { ButtonLabel } from '../button-label';
import { InkContainer } from '../ink-container';
import React from 'react';
import { resolveTextColor } from './resolve-text-color';

/**
 * Input base class name root.
 */
const ROOT_CLASS_NAME = 'button-base';

/**
 * Default component to use when rendering as an anchor.
 */
const ANCHOR_DEFAULT_COMPONENT = 'a';

/**
 * Default component to use when rendering as a button.
 */
const BUTTON_DEFAULT_COMPONENT = 'button';

/**
 * Picks button-specific properties.
 * @param props Input properties.
 */
const pickButtonProps = ({
  autoFocus,
  disabled,
  form,
  formAction,
  formEncType,
  formMethod,
  formNoValidate,
  formTarget,
  name,
  type,
  value,
}: ButtonBaseProps): React.ButtonHTMLAttributes<htmlelement> => ({
  autoFocus,
  disabled,
  form,
  formAction,
  formEncType,
  formMethod,
  formNoValidate,
  formTarget,
  name,
  type,
  value,
});

/**
 * Picks anchor-specific props.
 * @param props Input props.
 */
const pickAnchorProps = ({
  download,
  href,
  hrefLang,
  media,
  ping,
  rel,
  target,
  type,
  referrerPolicy,
}: ButtonBaseProps): React.AnchorHTMLAttributes<htmlelement> => ({
  download,
  href,
  hrefLang,
  media,
  ping,
  rel,
  target,
  type,
  referrerPolicy,
});

const ButtonBaseComponent = React.forwardRef<any, ButtonBaseProps="">(
  (props, ref): React.ReactElement => {
    const {
      autoFocus,
      children,
      className,
      component: Component = props.href
        ? ANCHOR_DEFAULT_COMPONENT
        : BUTTON_DEFAULT_COMPONENT,
      dense,
      disabled,
      download,
      form,
      formAction,
      formEncType,
      formMethod,
      formNoValidate,
      formTarget,
      href,
      hrefLang,
      inkOptions,
      media,
      name,
      noHoverStyles,
      noInk,
      ping,
      referrerPolicy,
      rel,
      target,
      type,
      value,
      ...rest
    } = props;

    const [blots, bindInk] = useInk({
      disabled: noInk || disabled,
      ...inkOptions,
    });

    const componentProps = {
      className: cx(ROOT_CLASS_NAME, className),
      ref,
      ...((Component === 'a'
        ? pickAnchorProps(rest)
        : pickButtonProps(rest)) as any),
      ...rest,
      ...bindInk,
    };

    return (
      <component {...componentProps}="">
        {barn}
        <inkcontainer>{Blots}</inkcontainer>
      </component>
    );
  },
);

ButtonBaseComponent.displayName = "ButtonBase";

const pickFont = (props: MedTema<buttonbaseprops>): TypographyVariant =>
  props.dense ? 'dense' : 'control';

const interpolateTextVariantStyles = (props: ButtonBaseProps): string => {
  const theme = assertTheme(props.theme);
  const { color } = props;
  const { textColor, textColorVar } = resolveTextColor(theme, color);

  if (props.noHoverStyles) {
    return '';
  }

  return `
    ::after {
      background: ${textColor};
      background: var(${textColorVar}, ${textColor});
      border-radius: inherit;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity 120ms;
      width: 100%;
    }

    :hover::after {
      opacity: 0.08;
    }
  `;
};

const resolveBackgroundColor = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): {
  background: string;
  hoverBackground: string;
  backgroundVar: string;
  hoverBackgroundVar: string;
} => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'error':
    case 'warning':
      return {
        background: theme.palette[color].main,
        backgroundVar: theme.vars.palette[color].main,
        hoverBackground: theme.palette[color].dark,
        hoverBackgroundVar: theme.vars.palette[color].dark,
      };
    case 'default':
    case 'inherit':
    default:
      return {
        background: theme.palette.neutral[200],
        backgroundVar: theme.vars.palette.neutral[200],
        hoverBackground: theme.palette.neutral[300],
        hoverBackgroundVar: theme.vars.palette.neutral[300],
      };
  }
};

const resolveBorderColor = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): {
  borderColor: string;
  borderColorVar: string;
  hoverBorderColor: string;
  hoverBorderColorVar: string;
} => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'error':
    case 'warning':
      return {
        borderColor: theme.palette[color].light,
        borderColorVar: theme.vars.palette[color].light,
        hoverBorderColor: theme.palette[color].main,
        hoverBorderColorVar: theme.vars.palette[color].main,
      };
    case 'inherit':
      return {
        borderColor: 'currentColor',
        borderColorVar: '',
        hoverBorderColor: 'currentColor',
        hoverBorderColorVar: '',
      };
    case 'default':
    default:
      return {
        borderColor: theme.palette.neutral[400],
        borderColorVar: theme.vars.palette.neutral[400],
        hoverBorderColor: theme.palette.neutral[500],
        hoverBorderColorVar: theme.vars.palette.neutral[500],
      };
  }
};

const resolveDarkBorderColor = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): {
  borderColor: string;
  borderColorVar: string;
} => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'error':
    case 'warning':
      return {
        borderColor: theme.palette[color].dark,
        borderColorVar: theme.vars.palette[color].dark,
      };
    case 'inherit':
      return {
        borderColor: 'currentColor',
        borderColorVar: '',
      };
    case 'default':
    default:
      return {
        borderColor: theme.palette.neutral[600],
        borderColorVar: theme.vars.palette.neutral[600],
      };
  }
};

const resolveContrastTextColor = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): { textColor: string; textColorVar: string } => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'error':
    case 'warning':
      return {
        textColor: theme.palette[color].contrastText,
        textColorVar: theme.vars.palette[color].contrastText,
      };
    case 'inherit':
      return {
        textColor: 'inherit',
        textColorVar: '',
      };
    case 'default':
    default:
      return {
        textColor: theme.palette.background.surface,
        textColorVar: theme.vars.palette.background.surface,
      };
  }
};

const interpolateOutlinedVariantStyles = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): string => {
  const {
    borderColor,
    borderColorVar,
    hoverBorderColor,
    hoverBorderColorVar,
  } = resolveBorderColor(theme, color);

  return `
    border: 1px solid ${borderColor};
    border: 1px solid var(${borderColorVar}, ${borderColor});

    :hover {
      border: 1px solid ${hoverBorderColor};
      border: 1px solid var(${hoverBorderColorVar}, ${hoverBorderColor});
    }
  `;
};

const interpolateRaisedVariantStyles = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): any => {
  const {
    background,
    backgroundVar,
    hoverBackground,
    hoverBackgroundVar,
  } = resolveBackgroundColor(theme, color);

  const { borderColor, borderColorVar } = resolveDarkBorderColor(theme, color);

  return css`
    background: ${background};
    background: var(${backgroundVar}, ${background});

    :hover {
      background: ${hoverBackground};
      background: var(${hoverBackgroundVar}, ${hoverBackground});
    }

    ::after {
      display: none;
    }

    ${ButtonGroup} &:not(:last-child) {
      border-right: 1px solid ${borderColor};
      border-right: 1px solid var(${borderColorVar}, ${borderColor});
    }
  `;
};

const interpolateVariantStyles = (props: ButtonBaseProps): any => {
  const theme = assertTheme(props.theme);
  switch (props.variant) {
    case 'outlined':
      return interpolateOutlinedVariantStyles(theme, props.color);
    case 'raised':
      return interpolateRaisedVariantStyles(theme, props.color);
    default:
      return '';
  }
};

const interpolateButtonLabelStyles = (props: ButtonBaseProps): string => {
  const theme = assertTheme(props.theme);
  const { color, variant } = props;
  if (variant === 'raised') {
    const { textColor, textColorVar } =
      color == null || color === 'default'
        ? resolveTextColor(theme, color)
        : resolveContrastTextColor(theme, color);

    return `
      color: ${textColor};
      color: var(${textColorVar}, ${textColor});
    `;
  } else {
    const { textColor, textColorVar } = resolveTextColor(theme, color);

    return `
      color: ${textColor};
      color: var(${textColorVar}, ${textColor});
    `;
  }
};

const interpolateButtonLabelHoverStyles = (props: ButtonBaseProps): string => {
  const theme = assertTheme(props.theme);
  const { color, variant } = props;

  if (variant === 'raised') {
    return '';
  } else {
    const { hoverTextColor, hoverTextColorVar } = resolveTextColor(
      theme,
      color,
    );
    return `
      color: ${hoverTextColor};
      color: var(${hoverTextColorVar}, ${hoverTextColor});
    `;
  }
};

export const ButtonBase = styled(ButtonBaseComponent)`
  ${interpolateFont(pickFont)}
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  transition: background-color 200ms, border-color 200ms;
  user-select: none;
  ${interpolateTextVariantStyles}
  ${interpolateVariantStyles}

  &::after {
    transition: opacity 200ms;
  }

  &,
  ${ButtonLabel}, & ${ButtonIcon}, ${InkContainer} {
    ${interpolateButtonLabelStyles}
  }

  :hover {
    ${ButtonLabel} {
      ${interpolateButtonLabelHoverStyles}
    }
  }

  ${ButtonGroup} &:not(:first-child) {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${ButtonGroup} &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
</buttonbaseprops></any,></htmlelement></htmlelement>