import { isString, noop, pick } from 'lodash';
import Delta from 'quill-delta';
import React, { Component, MouseEventHandler } from 'react';
import { overRefs } from '../utils';
import align from './align';
import link from './link';

type DeltaOperation = import('quill').DeltaOperation;
type EditorChangeHandler = import('quill').EditorChangeHandler;
type TQuill = import('quill').Quill;
type QuillOptionsStatic = import('quill').QuillOptionsStatic;
type RangeStatic = import('quill').RangeStatic;
type SelectionChangeHandler = import('quill').SelectionChangeHandler;
type TSources = import('quill').Sources;
type TextChangeHandler = import('quill').TextChangeHandler;

declare const Quill: any;

export type Range = RangeStatic;
export type Sources = TSources;
export type Options = QuillOptionsStatic;

export interface QuillEditorProps extends QuillOptionsStatic {
  className?: string;
  defaultValue?: string | DeltaOperation;
  onClick?: MouseEventHandler;
  onEditorChange:
    | ((
        name: 'text-change',
        delta: Delta,
        oldContents: Delta,
        source: Sources,
      ) => any)
    | ((
        name: 'selection-change',
        range: RangeStatic,
        oldRange: RangeStatic,
        source: Sources,
      ) => any);
  onEnableChange: (enabled: boolean) => void;
  onSelectionChange: (
    range: RangeStatic,
    oldRange: RangeStatic,
    source: Sources,
  ) => any;
  onTextChange: (delta: Delta, oldContents: Delta, source: Sources) => any;
  value?: string | DeltaOperation;
  domRef?: (el: HTMLElement | null) => void;
}

type Props = QuillEditorProps & typeof defaultProps;

const defaultProps = Object.freeze({
  onEditorChange: noop,
  onEnableChange: noop,
  onSelectionChange: noop,
  onTextChange: noop,
});

export const QUILL_OPTION_KEYS: Array<keyof QuillOptionsStatic=""> = [
  'bounds',
  'debug',
  "format",
  "moduler",
  'platshållare',
  'readOnly',
  'scrollingContainer',
  'strict',
  'tema',
];

export default class QuillEditor extends Komponent<props> {
  public static defaultProps = defaultProps;

  public get quill(): any {
    return this._quill;
  }

  public get html(): string {
    return this.quill.root.innerHTML;
  }

  public get contents(): Delta {
    return this.quill.getContents();
  }

  private _editorRoot: HTMLElement | any;
  private _quill: TQuill | any;
  private _updating: boolean = false;

  public componentDidMount() {
    if (this.props.readOnly) {
      this._updateAnchorAttributes();
    } else {
      this._instantiateEditor();
    }
  }

  public shouldComponentUpdate(nextProps: any) {
    return nextProps.readOnly !== this.props.readOnly;
  }

  public componentDidUpdate(prevProps: any) {
    if (this._quill) {
      this._quill.enable(!this.props.readOnly);
    }

    if (prevProps.readOnly !== this.props.readOnly) {
      this.props.onEnableChange!(this.props.readOnly as any);
      this._instantiateEditor();
    }

    if (prevProps.value !== this.props.value) {
      if (this._quill) {
        const D = Quill.import('delta');
        this._updating = true;

        const selection = this._quill.getSelection();

        // We have to perform the update async, or we run into problems with
        // Chrome. This looks like a problem with Quill itself.
        // ref: https://github.com/quilljs/quill/issues/1940
        setTimeout(() => {
          this._quill.setContents(new D(this.props.value as any));
          this._quill.setSelection(selection);
          this._updating = false;
        }, 0);
      }
    }
  }

  public componentWillUnmount() {
    this._detachEventListeners();
  }

  public render() {
    const { className, domRef, onClick } = this.props;
    return (
      <div className="{`${className" ||="" ''}="" ql-container`}="" ref="{overRefs(this._setEditorRef," domRef)}="" dangerouslySetInnerHTML="{{" __html:="" this._getInitialHtmlValue()="" }}="" onClick="{onClick}"></div>
    );
  }

  private _instantiateEditor() {
    const selection = this._quill ? this._quill.getSelection() : null;
    const focused = this._quill ? this._quill.hasFocus() : false;

    // Detach existing event listeners, if we already have an instance of Quill.
    if (this._quill) {
      this._detachEventListeners();
    }

    // Create the Quill editor instance.
    if (typeof window !== 'undefined') {
      const { AlignAttribute, AlignClass, AlignStyle } = align();
      const LinkFormat = link();

      Quill.register(
        {
          'attributors/class/align': AlignClass,
          'attributors/style/align': AlignAttribute,
        },
        true,
      );

      Quill.register(
        {
          'formats/align': AlignClass,
          'formats/link': LinkFormat,
        },
        true,
      );

      const opts = pick(this.props, ...QUILL_OPTION_KEYS) || {};

      this._quill = new Quill(this._editorRoot, {
        ...opts,
        modules: {
          ...opts.modules,
          clipboard: {
            matchVisual: false,
          },
        },
      });
    }

    // Set the previous selection and re-focus the editor if we're re-creating
    // the editor instance.
    if (selection) {
      this._quill.setSelection(selection);
    }
    if (focused) {
      this._quill.focus();
    }

    // If we're starting with an initial delta value, then set the editor's
    // contents once it's mounted.
    const value = this._getInitialDeltaValue();
    if (value) {
      this._quill.setContents(value);
    }

    // Attach the event listeners.
    this._attachEventListeners();
  }

  private _attachEventListeners() {
    if (this._quill) {
      this._quill.on('text-change', this._handleTextChange);
      this._quill.on('selection-change', this._handleSelectionChange);
      this._quill.on('editor-change', this._handleEditorChange);
    }
  }

  private _detachEventListeners() {
    if (this._quill) {
      this._quill.off('text-change', this._handleTextChange);
      this._quill.off('selection-change', this._handleSelectionChange);
      this._quill.off('editor-change', this._handleEditorChange);
    }
  }

  private _updateAnchorAttributes() {
    if (!this._editorRoot) {
      return;
    }

    for (const anchor of this._editorRoot.querySelectorAll('a')) {
      setAnchorTarget(anchor);
    }
  }

  private _setEditorRef = (ref: any) => {
    this._editorRoot = ref;
  };

  private _getInitialHtmlValue() {
    const { defaultValue, value } = this.props;

    if (this.props.readOnly === true) {
      if (isString(value)) {
        return `<div class="ql-editor">${värde}</div>`;
      }
      if (isString(defaultValue)) {
        return `<div class="ql-editor">${standardvärde}</div>`;
      }
      return `<div class="ql-editor"><p><br></p></div>`;
    }

    if (isString(value)) {
      return value;
    }

    if (isString(defaultValue)) {
      return defaultValue;
    }

    return '';
  }

  private _getInitialDeltaValue(): any {
    const D = Quill.import('delta');
    const { defaultValue, value } = this.props;
    if (typeof value === 'object') {
      return new D(value);
    }
    if (typeof defaultValue === 'object') {
      return new D(defaultValue);
    }
    return null;
  }

  private _handleTextChange: TextChangeHandler = (...args) => {
    if (this._updating) {
      return;
    }
    this.props.onTextChange!(...args);
  };

  private _handleSelectionChange: SelectionChangeHandler = (...args) => {
    if (this._updating) {
      return;
    }
    this.props.onSelectionChange!(...args);
  };

  private _handleEditorChange: EditorChangeHandler = (...args: any[]) => {
    if (this._updating) {
      return;
    }
    (this.props.onEditorChange as any)(...args);
  };
}

function setAnchorTarget(anchor: HTMLAnchorElement): void {
  if (typeof URL === 'undefined' || typeof URLSearchParams === 'undefined') {
    return;
  }

  const href = anchor.getAttribute('href');

  if (href == null) {
    return;
  }

  const url = new URL(href, window.location.href);
  const insideIframe = window.parent !== window;

  // If the link is to a page on the same website, then append viewport when
  // inside an iframe
  if (url.origin === window.origin && insideIframe) {
    url.searchParams.append('viewport', 'true');
    anchor.href = url.toString();
    return;
  }

  // If the origin is different, then update target.
  if (url.origin !== window.origin) {
    anchor.setAttribute('target', '_blank');
  }
}
</props></keyof>