import React, { HTMLAttributes, ReactNode, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

type FontTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'blockquote';

const styles = createStyles<'root'>(theme => ({
  root: ([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'blockquote',
  ] as FontTags[]).reduce(
    (r1, font) => ({
      ...r1,
      [`& ${font}, & .${font}`]: {
        ...theme.font(font),
        ...theme.vr(font),
      },
    }),
    {
      color: theme.color('text.body'),
      '& .text-align--left': {
        textAlign: 'left',
      },
      '& .text-align--right': {
        textAlign: 'right',
      },
      '& .text-align--center': {
        textAlign: 'center',
      },
      '& .text-align--justify': {
        textAlign: 'justify',
      },
      '& h1, & h2, & h3, & h4, & h5, & h6, & .h1, & .h2, & .h3, & .h4, & .h5, & .h6': {
        color: theme.color('text.heading'),
      },
      '& strong, & b': {
        fontWeight: 700,
      },
      '& em, & i': {
        fontStyle: 'italic',
      },
      '& a': {
        color: theme.color('text.link'),
      },
      '& ol, & ul': {
        ...theme.font('list'),
        ...theme.vr('list'),
      },
      '& hr': {
        background: theme.color('divider'),
        border: 'none',
        height: '1px',
        ...theme.vr('p'),
      },
      '& ol': {
        listStyleType: theme.list('ordered').listStyleType,
        marginLeft: theme.list('ordered').margin,
        listStylePosition: theme.list('ordered').listStylePosition,
      },
      '& ol li': {
        paddingLeft: theme.list('ordered').padding,
      },
      '& ul': {
        listStyleType: theme.list('unordered').listStyleType,
        marginLeft: theme.list('unordered').margin,
        listStylePosition: theme.list('unordered').listStylePosition,
      },
      '& ul li': {
        paddingLeft: theme.list('ordered').padding,
      },
      '& li ul, & li ol': {
        marginTop: 0,
        marginLeft: '1rem', // TODO: How should we handle nested lists?
      },
    } as any,
  ),
}));

export interface TypographyProps extends HTMLAttributes<htmldivelement> {
  children?: ReactNode;
  domRef?: (el: HTMLElement | null) => void;
}

type Props = WithStyles<typographyprops, typeof="" styles="">;

const Typografi: SFC<props> = ({
  barn,
  klassnamn,
  klasser,
  domRef,
  tema,
  ...rest
}) => (
  <div className="{cx(classes.root," className)}="" ref="{domRef}" {...rest}="">
    {barn}
  </div>
);

export default withStyles(styles)(Typografi);
</props></typographyprops,></htmldivelement>