import React, { HTMLAttributes, SFC } from 'react';
import ToolbarItem from '../ToolbarItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('grey', '200'),
    height: '100%',
    margin: '0 4px',
    minWidth: 0,
    padding: 0,
    pointerEvents: 'none',
    position: 'relative',
    width: 1,
  },
}));

export interface ToolbarSeparatorProps extends HTMLAttributes<htmldivelement> {}

typ Rekvisita = WithStyles<toolbarseparatorprops, typeof="" styles="">;

const VerktygsfältSeparator: SFC<props> = ({
  barn,
  klasser,
  klassnamn,
  tema,
  ...rest
}) => (
  <toolbaritem aria-hidden="true" className="{cx(classes.root," className)}="" {...rest}=""></toolbaritem>
);

export default withStyles(styles)(ToolbarSeparator);
</props></toolbarseparatorprops,></htmldivelement>