import React from 'react';
import ApolloClient from 'apollo-client';

export interface ApolloContextValue {
  client?: ApolloClient<object>;
  renderPromises?: Spela in<any, any="">;
}

låt apolloContext: React.kontext<apollocontextvalue>;

export function getApolloContext() {
  if (!apolloContext) {
    apolloContext = React.createContext<apollocontextvalue>({});
  }
  return apolloContext;
}

export function resetApolloContext() {
  apolloContext = React.createContext<apollocontextvalue>({});
}
</apollocontextvalue></apollocontextvalue></apollocontextvalue></any,></object>