import { filter, head, keys, map, reduce } from 'lodash';
import { Quill } from 'quill';
import React from 'react';
import ToolbarSeparator from '../ToolbarSeparator';
import AlignFlyout from './AlignmentFlyout';
import FontFlyout from './FontFlyout';
import FormatButton from './FormatButton';
import LinkButton from './LinkButton';
import ListFormatButton from './ListFormatButton';
import RedoButton from './RedoButton';
import UndoButton from './UndoButton';

import { ToolbarInterface } from './ToolbarInterface';

export type EditorItemKey =
  | 'add-comment'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'align'
  | 'attachment'
  | 'blockquote'
  | 'bold'
  | 'clear-format'
  | 'code'
  | 'dedent'
  | 'embed'
  | 'fill-color'
  | 'font-select'
  | 'font-size'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'highlight-color'
  | 'image'
  | 'indent'
  | 'italic'
  | 'link'
  | 'ordered-list'
  | 'p'
  | 'redo'
  | 'separator'
  | 'strike-through'
  | 'text-color'
  | 'underline'
  | 'undo'
  | 'unordered-list'
  | 'video';

export type EditorItem = EditorItemKey | Partial<{ [K in EditorItemKey]: any }>;

const createEditorItem = (
  item: EditorItem,
  index: number,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const key: string | EditorItemKey =
    typeof item === 'string' ? item : (head(keys(item)) as EditorItemKey);

  // FIXME: fix any cast.
  const options = typeof item === 'string' ? null : (item as any)[key];

  switch (key) {
    case 'bold':
    case 'italic':
    case 'underline':
    case 'strike-through':
    case 'blockquote':
      return <formatbutton key="{key}" format="{key}" quill="{quill}"></formatbutton>;

    fall 'separator':
      retur <toolbarseparator key="{index}"></toolbarseparator>;

    fall 'align':
      returnera <alignflyout key="{key}" quill="{quill}"></alignflyout>;

    fall "ångra":
      returnera <undobutton key="{key}" quill="{quill}"></undobutton>;

    fall "göra om":
      returnera <redobutton key="{key}" quill="{quill}"></redobutton>;

    fall 'font-val':
      returnera <fontflyout key="{key}" quill="{quill}" options="{options}"></fontflyout>;

    fall 'länk':
      returnera <linkbutton key="{key}" quill="{quill}" toolbar="{toolbar}"></linkbutton>;

    fall "beställd-lista":
    fall 'oordnad-lista':
      retur <listformatbutton key="{key}" format="{key}" quill="{quill}"></listformatbutton>;

    fall 'lägg till kommentar':
    fall 'attachment':
    fall 'clear-format':
    fall 'kod':
    fall 'dedent':
    fall 'embed':
    fall 'fill-color':
    fall 'font-size':
    fall 'highlight-color':
    fall 'image':
    fall 'indent':
    fall 'text-färg':
    fall "video":

    fall 'align-center':
    fall 'align-justify':
    fall 'align-left':
    fall "align-right":

    fall 'h1':
    case 'h2':
    fall 'h3':
    fall 'h4':
    fall "h5
    fall "h6":

    fall 'p':
      throw new Error('Ej implementerad.');
  }
};

export default (
  objekt: Array<editoritem |="" EditorItem[]="">,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const reduced = reduce(
    items,
    (acc: EditorItem[], item: EditorItem | EditorItem[]) => {
      if (Array.isArray(item)) {
        return acc.concat(['separator', ...item, 'separator']);
      }
      return acc.concat([item]);
    },
    [] as any,
  );

  const filtered = filter(reduced, (item, i: any, array) => {
    // Don't show separator as first/last items.
    if (i === 0 || i === array.length - 1) return item !== 'separator';
    // Don't show consecutive separators.
    if (item === 'separator' && array[i - 1] === 'separator') return false;
    return true;
  });

  return map(filtered, (item, i) => createEditorItem(item, i, quill, toolbar));
};
</editoritem>