import EventEmitter from 'eventemitter3';
import { castArray, some, startsWith } from 'lodash';
import callIfFunction from './callIfFunction';
import warning from './warning';

export interface WindowMessengerOptions {
  psk?: string;
  allowedOrigins?: string | RegExp | (string | RegExp)[];
  targetOrigin?: string;
  target: Window | (() => Window);
}

const rpcName = (name: string) => `__rpc/${name}`;
const isRpc = (name: string) => startsWith(name, '__rpc/');

export default class WindowMessenger extends EventEmitter {
  private _options: WindowMessengerOptions;
  private _procedures: {
    [name: string]: (req: any) => any;
  } = {};

  constructor(options: WindowMessengerOptions) {
    super();
    this._options = {
      allowedOrigins:
        typeof window !== 'undefined' ? window.location.origin : [],
      psk: '__rslib__',
      target: null,
      targetOrigin: '*',
      ...options,
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('message', this._receive, false);
    }
  }

  public send = (message: string, data: any = null) => {
    if (typeof window === 'undefined') return;
    const _target = callIfFunction(this._options.target)();
    const _targetOrigin = this._options.targetOrigin;

    warning(_target, 'No target available to message.');
    if (!_target) return;

    _target.postMessage(
      {
        message,
        data: data && typeof data.toJSON === 'function' ? data.toJSON() : data,
        psk: this._options.psk,
      },
      _targetOrigin as string,
    );

    this.emit('_send', {
      _target,
      _targetOrigin,
      data,
      message,
    });
  };

  public rpc = <req, Res="">(namn: sträng, data?: Req): Löfte<res> => {
    return new Promise((resolve, reject) => {
      const replyTo = Math.random()
        .toString(36)
        .substring(2);

      this.send(rpcName(name), {
        data,
        replyTo,
      });

      this.once(replyTo, ({ data: res, err }) => {
        if (err) {
          const e = new Error(err);
          return reject(e);
        }
        return resolve(res);
      });
    });
  };

  public registerProcedure = <req, Res="">(
    namn: sträng,
    fn: (req: Req) => Res | Promise<res>,
  ): this => {
    if (this._procedures[name]) {
      throw new Error(`Procedure with name ${name} is already registered.`);
    }
    this._procedures[name] = fn;
    return this;
  };

  public unregisterProcedure(name: string): this {
    delete this._procedures[name];
    return this;
  }

  private _receive = async (event: any) => {
    const { allowedOrigins, psk } = this._options;
    const { data: receiveData, origin } = event;

    if (receiveData.psk !== psk) return;

    if (
      !some(castArray(allowedOrigins), orig => {
        if (typeof orig === 'string') return orig === origin;
        if (orig instanceof RegExp) return orig.test(origin);
        return false;
      })
    ) {
      return false;
    }

    this.emit(receiveData.message, receiveData.data);
    this.emit('_receive', receiveData);

    if (isRpc(receiveData.message)) {
      if (!this._procedures[receiveData.name]) return;
      const res = await Promise.resolve(
        this._procedures[receiveData.name](receiveData.data),
      );
      this.send(receiveData.replyTo, res);
    }
  };
}
</res></req,></res></req,>