import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    '& > *': {
      margin: '0.25em',
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}));

export interface ButtonRowProps extends HTMLAttributes<htmldivelement> {}

typ Rekvisita = WithStyles<buttonrowprops, typeof="" styles="">;

const ButtonRow: SFC<props> = ({
  barn,
  klasser,
  klassnamn,
  tema,
  ...rest
}) => (
  <div className="{cx(classes.root," className)}="" {...rest}="">
    {barn}
  </div>
);

export default withStyles(styles)(ButtonRow);
</props></buttonrowprops,></htmldivelement>