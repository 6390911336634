import { forEach, omit, pick } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { Component, HTMLAttributes } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { CSSLock, cx, lock, overRefs } from '../utils';

const styles = createStyles<'root' | 'locked'>(theme => ({
  root: {
    height: '100%',
    maxWidth: '100%',
    position: 'relative',
    '&$locked.ps': {
      overflow: 'visible !important',
    },
    '&$locked.ps .ps__rail-x, &$locked.ps .ps__rail-y': {
      display: 'none',
    },
    '&.ps': {
      overflow: 'hidden !important',
      overflowAnchor: 'none',
      '-ms-overflow-style': 'none',
      touchAction: 'auto',
      '-ms-touch-action': 'auto',
    },
    '& .ps__rail-x, & .ps__rail-y': {
      zIndex: 1,
    },
    '& .ps__rail-x ': {
      display: 'none',
      opacity: 0,
      transition: 'background-color 0.2s linear, opacity 0.2s linear',
      height: 15,
      bottom: 0,
      position: 'absolute',
    },
    '& .ps__rail-y': {
      display: 'none',
      opacity: 0,
      transition: 'background-color 0.2s linear, opacity 0.2s linear',
      width: 15,
      right: 0,
      position: 'absolute',
    },
    [['&.ps--active-x > .ps__rail-x', '&.ps--active-y > .ps__rail-y'].join(
      ',',
    )]: {
      display: 'block',
      backgroundColor: 'transparent',
    },
    [[
      '&.ps:hover > .ps__rail-x',
      '&.ps:hover > .ps__rail-y',
      '&.ps--focus > .ps__rail-x',
      '&.ps--focus > .ps__rail-y',
      '&.ps--scrolling-x > .ps__rail-x',
      '&.ps--scrolling-y > .ps__rail-y',
    ].join(',')]: {
      opacity: 0.6,
    },
    [[
      '&.ps__rail-x:hover',
      '&.ps__rail-y:hover',
      '&.ps__rail-x:focus',
      '&.ps__rail-y:focus',
    ].join(',')]: {
      backgroundColor: '#eee',
      opacity: 0.9,
    },
    '& .ps__thumb-x ': {
      backgroundColor: '#aaa',
      borderRadius: 6,
      transition: 'background-color 0.2s linear, height 0.2s ease-in-out',
      height: 6,
      bottom: 2,
      position: 'absolute',
    },
    '& .ps__thumb-y': {
      backgroundColor: '#aaa',
      borderRadius: 6,
      transition: 'background-color 0.2s linear, width 0.2s ease-in-out',
      width: 6,
      right: 2,
      position: 'absolute',
    },
    [[
      '& .ps__rail-x:hover > .ps__thumb-x',
      '& .ps__rail-x:focus > .ps__thumb-x',
    ].join(',')]: {
      backgroundColor: '#999',
      height: 11,
    },
    [[
      '& .ps__rail-y:hover > .ps__thumb-y',
      '& .ps__rail-y:focus > .ps__thumb-y',
    ].join(',')]: {
      backgroundColor: '#999',
      width: 11,
    },
    '@supports (-ms-overflow-style: none)': {
      '&.ps': {
        overflow: 'auto !important',
      },
    },
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) ': {
      '&.ps': {
        overflow: 'auto !important',
      },
    },
  },
  locked: {},
}));

export type ScrollerProps = {
  domRef?: (el: HTMLElement | null) => void;
  locked?: boolean;
  maxScrollbarLength?: number;
  minScrollbarLength?: number;
  onScrollDown?: () => void;
  onScrollLeft?: () => void;
  onScrollRight?: () => void;
  onScrollUp?: () => void;
  onScrollX?: () => void;
  onScrollY?: () => void;
  onXReachEnd?: () => void;
  onXReachStart?: () => void;
  onYReachEnd?: () => void;
  onYReachStart?: () => void;
  scrollingThreshold?: number;
  scrollXMarginOffset?: number;
  scrollYMarginOffset?: number;
  suppressScrollX?: boolean;
  suppressScrollY?: boolean;
  swipeEasing?: boolean;
  useBothWheelAxis?: number;
  wheelPropagation?: boolean;
  wheelSpeed?: number;
} & HTMLAttributes<htmlelement>;

typ Props = WithStyles<scrollerprops, typeof="" styles="">;

type State = {
  scrollTop?: number;
};

const initialState = Object.freeze({});

const buildPSOptions = (props: ScrollerProps) =>
  pick(
    props,
    'handlers',
    'wheelSpeed',
    'wheelPropagation',
    'swipeEasing',
    'minScrollbarLength',
    'maxScrollbarLength',
    'scrollingThreshold',
    'useBothWheelAxis',
    'suppressScrollX',
    'suppressScrollY',
    'scrollXMarginOffset',
    'scrollYMarginOffset',
  );

const eventMap = {
  onScrollDown: 'ps-scroll-down',
  onScrollLeft: 'ps-scroll-left',
  onScrollRight: 'ps-scroll-right',
  onScrollUp: 'ps-scroll-up',
  onScrollX: 'ps-scroll-x',
  onScrollY: 'ps-scroll-y',
  onXReachEnd: 'ps-x-reach-end',
  onXReachStart: 'ps-x-reach-start',
  onYReachEnd: 'ps-y-reach-end',
  onYReachStart: 'ps-y-reach-start',
};

class Scroller extends Component<props, State=""> {
  state = initialState;

  public static defaultProps = {
    minScrollbarLength: 20,
  };

  private _el: HTMLElement | null = null;
  private _ps: any;
  private _scrollLock: CSSLock | null = null;

  public componentDidMount() {
    this._createPs(this.props);
  }

  getSnapshotBeforeUpdate() {
    if (this._el && !this.props.suppressScrollY) {
      return this._el.scrollTop;
    } else if (this._el && this.props.suppressScrollY) {
      return this._el.scrollLeft;
    }
  }

  componentDidUpdate(prevProps: ScrollerProps, _: State, snapshot: number) {
    if (this._el) {
      if (this.props.suppressScrollY) {
        if (this.props.locked === true && prevProps.locked === false) {
          this._el.style.marginLeft = `${-snapshot}px`;
        } else if (this.props.locked === false && prevProps.locked === true) {
          this._el.style.marginLeft = '';
        }
      } else {
        if (this.props.locked === true && prevProps.locked === false) {
          this._el.style.marginTop = `${-snapshot}px`;
        } else if (this.props.locked === false && prevProps.locked === true) {
          this._el.style.marginTop = '';
        }
      }
    }
    if (prevProps.children !== this.props.children && this._ps) {
      this._ps.update();
    }
  }

  public componentWillUnmount() {
    this._destroyPs();
    this._clearScrollLock();
  }

  public render() {
    const { classes, className, domRef, locked, theme, ...rest } = this.props;
    const divProps = omit(rest, [
      'handlers',
      'locked',
      'wheelSpeed',
      'wheelPropagation',
      'swipeEasing',
      'minScrollbarLength',
      'maxScrollbarLength',
      'scrollingThreshold',
      'useBothWheelAxis',
      'suppressScrollX',
      'suppressScrollY',
      'scrollXMarginOffset',
      'scrollYMarginOffset',
      'onScrollY',
      'onScrollX',
      'onScrollUp',
      'onScrollDown',
      'onScrollLeft',
      'onScrollRight',
      'onYReachStart',
      'onYReachEnd',
      'onXReachStart',
      'onXReachEnd',
    ]);

    return (
      <div {...divProps}="" ref="{overRefs(this._setScrollerRef," domRef)}="" className="{cx(classes.root," locked="" &&="" classes.locked,="" className,="" 'ps')}=""></div>
    );
  }

  private _setScrollerRef = (el: HTMLElement | null) => {
    this._el = el;
  };

  private _createPs(props: ScrollerProps) {
    const node = this._el as HTMLDivElement;
    this._ps = new PerfectScrollbar(node, buildPSOptions(props));

    forEach(eventMap, (event, prop) => {
      const handler = this.props[prop as keyof Props];
      if (handler) {
        node.addEventListener(event, handler);
      }
    });
  }

  private _destroyPs() {
    if (this._ps) {
      this._ps.destroy();
      this._ps = null;
    }
  }

  private _setScrollLock() {
    this._clearScrollLock();
    if (this._el) {
      this._scrollLock = lock('body', 'scroll');
    }
  }

  private _clearScrollLock() {
    if (this._scrollLock) this._scrollLock.unlock();
  }
}

export default withStyles(styles)(Scroller);
</props,></scrollerprops,></htmlelement>