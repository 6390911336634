import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    lineHeight: 2,
    padding: '0 0.5em',
  },
}));

export interface ListItemProps extends HTMLAttributes<htmldivelement> {}

typ Rekvisita = WithStyles<listitemprops, typeof="" styles="">;

const ListItem: SFC<props> = ({klasser, klassnamn, tema, ...rekvisita }) => (
  <div role="listitem" className="{cx(classes.root," className)}="" {...props}=""></div>
);

export default withStyles(styles)(ListItem) as SFC<listitemprops>;
</listitemprops></props></listitemprops,></htmldivelement>