import { Theme, TypographyColor, TypographyVariant } from '../theme';
import { Interpolator } from './interpolator';
import { assertTheme } from '../util';
import { interpolateFontSize } from './interpolate-font-size';
import { interpolateTextColor } from './interpolate-text-color';

export const interpolateFont = <tprops extends="" {="" theme:="" Theme="" }="">(
  variantOrFn: TypographyVariant | ((props: TProps) => TypographyVariant),
  colorOrFn?:
    | TypographyColor
    | ((props: TProps) => TypographyColor | undefined),
): Interpolator<tprops> => (props): string => {
  // Compute the variant from parameters.
  const variant =
    typeof variantOrFn === 'function' ? variantOrFn(props) : variantOrFn;

  const theme = assertTheme(props);
  const font = theme.typography[variant];
  const vars = theme.vars.typography[variant];

  const color = colorOrFn != null ? interpolateTextColor(colorOrFn)(props) : '';

  return `
    font-family: ${font.fontFamily};
    font-family: var(${vars.fontFamily}, ${font.fontFamily});
    font-weight: ${font.fontWeight};
    font-weight: var(${vars.fontWeight}, ${font.fontWeight});
    font-stretch: ${font.fontStretch};
    font-stretch: var(${vars.fontStretch}, ${font.fontStretch});
    font-style: ${font.fontStyle};
    font-style: var(${vars.fontStyle}, ${font.fontStyle});
    font-variant: ${font.fontVariant};
    font-variant: var(${vars.fontVariant}, ${font.fontVariant});
    letter-spacing: ${font.letterSpacing};
    letter-spacing: var(${vars.letterSpacing}, ${font.letterSpacing});
    line-height: ${font.lineHeight};
    line-height: var(${vars.lineHeight}, ${font.lineHeight});
    text-transform: ${font.textTransform};
    text-transform: var(${vars.textTransform}, ${font.textTransform});
    ${interpolateFontSize(variant)(props)}
    ${color}
  `;
};
</tprops></tprops>