import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    position: 'relative',
  },
}));

export interface CardMediaProps extends HTMLAttributes<htmldivelement> {
  domRef?: (el: HTMLElement | null) => void;
}

type Props = WithStyles<cardmediaprops, typeof="" styles="">;

const CardMedia: SFC<props> = ({
  klassnamn,
  klasser,
  tema,
  domRef,
  ...rest
}) => <div ref="{domRef}" className="{cx(classes.root," className)}="" {...rest}=""></div>;

export default withStyles(styles)(CardMedia);
</props></cardmediaprops,></htmldivelement>