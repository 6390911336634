import { Quill } from 'quill';
import React, { SFC } from 'react';
import IconMenuItem from '../IconMenuItem';
import Menu from '../Menu';
import ToolbarFlyout from '../ToolbarFlyout';

import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
} from '@robotsnacks/icons';

type AlignFlyoutProps = {
  quill: Quill;
};

type FlyoutProps = {
  quill: Quill;
};

const Flyout: SFC<flyoutprops> = ({ quill }) => (
  <menu>
    <iconmenuitem onClick="{()" ==""> quill.format('align', odefinierad, 'användare')}>
      <formatalignleft></formatalignleft>
    </iconmenuitem>
    <iconmenuitem onClick="{()" ==""> quill.format('align', 'center', 'user')}>
      <formataligncenter></formataligncenter>
    </iconmenuitem>
    <iconmenuitem onClick="{()" ==""> quill.format('align', 'right', 'user')}>
      <formatalignright></formatalignright>
    </iconmenuitem>
    <iconmenuitem onClick="{()" ==""> quill.format('align', 'justify', 'user')}>
      <formatalignjustify></formatalignjustify>
    </iconmenuitem>
  </menu>
);

const getCurrentAlignment = (quill: Quill) => {
  const selection = quill.getSelection();
  return (selection && quill.getFormat().align) || 'left';
};

const getAlignmentIcon = (alignment: string) => {
  switch (alignment) {
    case 'center':
      return <formataligncenter></formataligncenter>;
    fall "höger":
      retur <formatalignright></formatalignright>;
    fall "rättfärdiga":
      retur <formatalignjustify></formatalignjustify>;
    fall "vänster":
    standard:
      retur <formatalignleft></formatalignleft>;
  }
};

const AlignmentFlyout: SFC<alignflyoutprops> = ({ quill }) => (
  <toolbarflyout flyout="{<Flyout" quill="{quill}"></toolbarflyout>}>
    {getAlignmentIcon(getCurrentAlignment(quill))}
  
);

export default AlignmentFlyout;
</alignflyoutprops></flyoutprops>