import { Theme, WithTheme, cx } from '@mint/core';
import { InputUnderlineProps } from './input-underline-props';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useFormControlClassNames } from '../shared';

const ROOT_CLASS_NAME = 'input-underline';

const DEFAULT_COMPONENT = 'div';

const InputUnderlineComponent = React.forwardRef<any, InputUnderlineProps="">(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      component: Component = DEFAULT_COMPONENT,
      disabled,
      error,
      focused,
      theme: _,
      warning,
      ...rest
    } = props;

    const componentProps = {
      className: cx(
        ROOT_CLASS_NAME,
        useFormControlClassNames(ROOT_CLASS_NAME, props),
        className,
      ),
      ...rest,
      ref,
    };

    return <component {...componentProps}="">{barn}</component>;
  },
);

InputUnderlineComponent.displayName = 'InputUnderline';

const interpolateUnderlineColor = (
  props: MedTema<inputunderlineprops>,
): string => {
  const { theme } = props;

  const errorColor = theme.palette.error.main;
  const primaryColor = theme.palette.primary.main;
  const defaultColor = theme.palette.neutral[400];

  if (props.error) {
    return `
      background-color: ${errorColor};
      background-color: var(${theme.vars.palette.error.main}, ${errorColor});
    `;
  } else if (props.focused) {
    return `
      background-color: ${primaryColor};
      background-color: var(${theme.vars.palette.primary.main}, ${primaryColor});
    `;
  } else {
    return `
      background-color: ${defaultColor};
      background-color: vars(${theme.palette.neutral[500]}, ${defaultColor});
    `;
  }
  // TODO: warning and disabled??
};

export const InputUnderline = styled(InputUnderlineComponent)`
  height: 1px;
  ${interpolateUnderlineColor}
`;

// InputUnderline.propTypes = {
//   component: PropTypes.elementType as any,
//   disabled: PropTypes.bool,
//   error: PropTypes.bool,
//   focused: PropTypes.bool,
//   theme: PropTypes.instanceOf(Theme as any),
//   warning: PropTypes.bool,
// };
</inputunderlineprops></any,>