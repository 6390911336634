/* eslint-disable @typescript-eslint/no-explicit-any */
import { Breakpoint, Font } from './types';
import {
  DEFAULT_BASELINE,
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_STRETCH,
  DEFAULT_FONT_STYLE,
  DEFAULT_FONT_VARIANT,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_LETTER_SPACING,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_RVR,
  DEFAULT_TEXT_TRANSFORM,
  DEFAULT_VR,
} from './constants';
import { FontInput, ThemeInput } from './theme-input';
import { ThemeJson } from './theme-json';

const makeFontSize = <tcustombreakpoint extends="" string="never">(
  customBreakpoints: { [K in TCustomBreakpoint]: number },
  fontSize?: string | Font<tcustombreakpoint>['fontSize'],
): Teckensnitt<tcustombreakpoint>['fontSize'] => {
  const customBreakpointFontSizes: any = Object.keys(customBreakpoints).reduce(
    (curr, key): any => ({
      ...curr,
      [key]: DEFAULT_VR,
    }),
    {},
  );
  if (typeof fontSize === 'string') {
    return {
      ...customBreakpointFontSizes,
      xs: fontSize,
      sm: fontSize,
      md: fontSize,
      lg: fontSize,
      xl: fontSize,
    };
  } else if (fontSize) {
    return {
      ...DEFAULT_FONT_SIZE,
      ...fontSize,
    };
  } else {
    return {
      ...customBreakpointFontSizes,
      ...DEFAULT_FONT_SIZE,
    };
  }
};

const makeRvr = <tcustombreakpoint extends="" string="never">(
  customBreakpoints: { [K in TCustomBreakpoint]: number },
  input: Partial<
    {
      [K in Breakpoint | TCustomBreakpoint]: number;
    }
  > = {},
): {
  [K in Breakpoint | TCustomBreakpoint]: number;
} => {
  const customBreakpointRvr = Object.keys(customBreakpoints).reduce(
    (curr, key): any => ({
      ...curr,
      [key]: DEFAULT_VR,
    }),
    {},
  );
  return {
    ...customBreakpointRvr,
    ...DEFAULT_RVR,
    ...input,
  } as any;
};

const makeFont = <tcustombreakpoint extends="" string="never">(
  brytpunkter: TemaInmatning<tcustombreakpoint>['breakpoints'] = {} as any,
  {
    fontFamily = DEFAULT_FONT_FAMILY,
    fontSize,
    fontStretch = DEFAULT_FONT_STRETCH,
    fontStyle = DEFAULT_FONT_STYLE,
    fontVariant = DEFAULT_FONT_VARIANT,
    fontWeight = DEFAULT_FONT_WEIGHT,
    letterSpacing = DEFAULT_LETTER_SPACING,
    lineHeight = DEFAULT_LINE_HEIGHT,
    textTransform = DEFAULT_TEXT_TRANSFORM,
    vr = DEFAULT_VR,
    ...rest
  }: FontInput<tcustombreakpoint> = {},
): Font<tcustombreakpoint> => {
  const rvr = makeRvr(breakpoints, rest.rvr);
  return {
    fontFamily,
    fontSize: makeFontSize(breakpoints, fontSize as any),
    fontStretch,
    fontStyle,
    fontVariant,
    fontWeight,
    letterSpacing,
    lineHeight,
    rvr,
    textTransform,
    vr,
  };
};

export class Typography<tcustombreakpoint extends="" string="never"> {
  offentligt statiskt skapa<tcustombreakpoint extends="" string="never">(
    breakpoints: { [K in TCustomBreakpoint | Breakpoint]: number },
    input: ThemeInput<tcustombreakpoint>['typography'] = {},
  ): Typography<tcustombreakpoint> {
    return new Typography({
      baseline: input.baseline || DEFAULT_BASELINE,
      body: makeFont(breakpoints, input.body),
      control: makeFont(breakpoints, input.control),
      button: makeFont(breakpoints, input.button),
      caption: makeFont(breakpoints, input.caption),
      dense: makeFont(breakpoints, input.dense),
      h1: makeFont(breakpoints, input.h1),
      h2: makeFont(breakpoints, input.h2),
      h3: makeFont(breakpoints, input.h3),
      h4: makeFont(breakpoints, input.h4),
      h5: makeFont(breakpoints, input.h5),
      h6: makeFont(breakpoints, input.h6),
      helper: makeFont(breakpoints, input.helper),
      overline: makeFont(breakpoints, input.overline),
      subtitle: makeFont(breakpoints, input.subtitle),
    });
  }

  public static toJSON<tcustombreakpoint extends="" string="never">(
    breakpoints: { [K in TCustomBreakpoint | Breakpoint]: number },
    input: ThemeInput<tcustombreakpoint>['typography'] = {},
  ): ThemeJson<tcustombreakpoint>['typography'] {
    return Typography.create(breakpoints, input).toJSON();
  }

  public baseline: ThemeJson<tcustombreakpoint>["typografi"]["baslinje"];
  offentligt organ: ThemeJson<tcustombreakpoint>['typografi']['body'];
  public button: ThemeJson<tcustombreakpoint>['typografi']['knapp'];
  public bildtext: ThemeJson<tcustombreakpoint>['typografi']['bildtext'];
  offentlig kontroll: ThemeJson<tcustombreakpoint>["typografi"]["kontroll"];
  offentlig tät: ThemeJson<tcustombreakpoint>['typografi']['tät'];
  public h1: TemaJson<tcustombreakpoint>['typografi']['h1'];
  public h2: ThemeJson<tcustombreakpoint>['typografi']['h2'];
  public h3: ThemeJson<tcustombreakpoint>['typografi']['h3'];
  public h4: ThemeJson<tcustombreakpoint>['typografi']['h4'];
  public h5: ThemeJson<tcustombreakpoint>['typografi']['h5'];
  public h6: ThemeJson<tcustombreakpoint>['typografi']['h6'];
  public hjälpare: ThemeJson<tcustombreakpoint>['typografi']['hjälpmedel'];
  public overline: ThemeJson<tcustombreakpoint>['typografi']['överstrykning'];
  public undertitel: ThemeJson<tcustombreakpoint>["typografi"]["undertitel"];

  public constructor(typografi: ThemeJson<tcustombreakpoint>['typography']) {
    this.baseline = typography.baseline;
    this.body = typography.body;
    this.button = typography.button;
    this.caption = typography.caption;
    this.control = typography.control;
    this.dense = typography.dense;
    this.h1 = typography.h1;
    this.h2 = typography.h2;
    this.h3 = typography.h3;
    this.h4 = typography.h4;
    this.h5 = typography.h5;
    this.h6 = typography.h6;
    this.helper = typography.helper;
    this.overline = typography.overline;
    this.subtitle = typography.subtitle;
  }

  public toJSON(): ThemeJson<tcustombreakpoint>['typography'] {
    return {
      baseline: this.baseline,
      body: this.body,
      button: this.button,
      caption: this.caption,
      control: this.control,
      dense: this.dense,
      h1: this.h1,
      h2: this.h2,
      h3: this.h3,
      h4: this.h4,
      h5: this.h5,
      h6: this.h6,
      helper: this.helper,
      overline: this.overline,
      subtitle: this.subtitle,
    };
  }
}
</tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint>