import React, { HTMLAttributes, ReactNode, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    alignItems: 'center',
    color: theme.color('grey', '200'),
    display: 'flex',
    fontSize: '2rem',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
  },
}));

export interface EmptyPickerProps extends HTMLAttributes<htmldivelement> {
  className?: string;
  children?: ReactNode;
}

type Props = WithStyles<emptypickerprops, typeof="" styles="">;

const defaultProps = Object.freeze({
  children: 'Not Found' as ReactNode,
});

const EmptyPicker: SFC<props> = ({klasser, klassnamn, barn }) => (
  <div className="{cx(classes.root," className)}="">
    {barn || defaultProps.barn}
  </div>
);

export default withStyles(styles)(EmptyPicker);
</props></emptypickerprops,></htmldivelement>