import { clamp } from 'lodash';
import React, { Component, HTMLAttributes, ReactNode, createRef } from 'react';
import { ArrowDirection } from '../Arrow';
import ArrowBox from '../ArrowBox';
import Magnet, { MagnetAnchor } from '../Magnet';
import { PaperProps } from '../Paper';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const DEFAULT_ARROW_SIZE = 6;

const styles = createStyles<'root' | 'up' | 'down' | 'left' | 'right'>(() => {
  const arrowSize: any = ({ size = DEFAULT_ARROW_SIZE }: MagnetArrowBoxProps) =>
    size;
  return {
    root: {},
    up: {
      marginTop: arrowSize,
    },
    down: {
      marginBottom: arrowSize,
    },
    left: {
      marginLeft: arrowSize,
    },
    right: {
      marginRight: arrowSize,
    },
  };
});

export interface MagnetArrowBoxProps
  extends HTMLAttributes<htmldivelement>,
    Plocka<paperprops, 'elevation'=""> {
  anchor?: MagnetAnchor;
  arrowBoxClassName?: string;
  children?: ReactNode;
  color?: string;
  domRef?: (el: HTMLElement | null) => void;
  size?: number;
  slide?: boolean;
  swap?: boolean;
}

type Props = WithStyles<magnetarrowboxprops, typeof="" styles="">;

const calculateArrowLeft = (
  targetRect: DOMRect | ClientRect,
  parentRect: DOMRect | ClientRect,
  size: number = DEFAULT_ARROW_SIZE,
): number => {
  const originLeft = targetRect.left + targetRect.width / 2;
  const optimalLeft = targetRect.width / 2;
  const parentLeft = originLeft - parentRect.left;
  const parentRight = originLeft - parentRect.right;

  return clamp(
    optimalLeft,
    optimalLeft - parentLeft + size,
    optimalLeft - Math.max(0, parentRight + size),
  );
};

const calculateArrowTop = (
  targetRect: DOMRect | ClientRect,
  parentRect: DOMRect | ClientRect,
  size: number = DEFAULT_ARROW_SIZE,
): number => {
  const originTop = targetRect.top + targetRect.height / 2;
  const optimalTop = targetRect.height / 2;
  const parentTop = originTop - parentRect.top;
  const parentBottom = originTop - parentRect.bottom;

  return clamp(
    optimalTop,
    optimalTop - parentTop + size,
    optimalTop - Math.max(0, parentBottom + size),
  );
};

const calculateArrowDirection = (anchor: MagnetAnchor): ArrowDirection => {
  switch (anchor) {
    case 'east-bottom':
    case 'east-north-east':
    case 'east-south-east':
    case 'east-top':
    case 'east':
      return 'left';

    case 'south-left':
    case 'south-right':
    case 'south-south-east':
    case 'south-south-west':
    case 'south':
      return 'up';

    case 'west-bottom':
    case 'west-north-west':
    case 'west-south-west':
    case 'west-top':
    case 'west':
      return 'right';

    case 'north-left':
    case 'north-north-east':
    case 'north-north-west':
    case 'north-right':
    case 'north':
    default:
      return 'down';
  }
};

class MagnetArrowBox extends Component<props> {
  privat _magnet = createRef<magnet>();

  render() {
    const {
      anchor,
      arrowBoxClassName,
      children,
      classes,
      className,
      domRef,
      slide,
      size = DEFAULT_ARROW_SIZE,
      swap,
      theme,
      ...rest
    } = this.props;
    return (
      <magnet padding="{DEFAULT_ARROW_SIZE}" className="{className}" anchor="{anchor}" slide="{slide}" swap="{swap}" ref="{this._magnet}" {...rest}="">
        {magnetProps => {
          const { node } = magnetProps;

          let arrowLeft: number | undefined;
          let arrowTop: number | undefined;

          if (node && node.offsetParent) {
            const targetRect = node.getBoundingClientRect();
            const parentRect = node.offsetParent.getBoundingClientRect();
            arrowLeft = calculateArrowLeft(targetRect, parentRect);
            arrowTop = calculateArrowTop(targetRect, parentRect);
          }

          const direction = calculateArrowDirection(magnetProps.anchor);

          return (
            <arrowbox arrowLeft="{arrowLeft}" arrowTop="{arrowTop}" className="{cx(classes[direction]," arrowBoxClassName)}="" direction="{direction}" domRef="{domRef}">
              {barn}
            </arrowbox>
          );
        }}
      </magnet>
    );
  }

  getDOMNode(): HTMLDivElement | null {
    const { current } = this._magnet;
    if (current) return current.getDOMNode();
    return null;
  }
}

export default withStyles(styles)(MagnetArrowBox);
</magnet></props></magnetarrowboxprops,></paperprops,></htmldivelement>